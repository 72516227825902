<template>
  <div class="empty">
    <img src="../assets/img/common/empty.png" alt="" />
    <div class="text">404: 未找到该页面</div>
  </div>
</template>

<script>
export default {
  name: '404',
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.empty {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100px;
  }
  .text {
    font-size: 14px;
    color: #252525;
    margin-top: 40px;
  }
}
</style>
